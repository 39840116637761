@tailwind base;
@tailwind components;
@tailwind utilities;

.btn {
  background: linear-gradient(
    92.23deg,
    #ff56f6 21.43%,
    #b936ee 50.63%,
    #3bace2 100%,
    #406aff 117.04%
  );
  box-shadow: 0px 4.42184px 107.23px rgba(255, 86, 246, 0.51);
}

.btn:hover {
  background: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #3bace2 50.63%,
    #b936ee 100%,
    #ff56f6 117.04%
  );
}

.gradient,
.active {
  background: linear-gradient(
    92.23deg,
    #406aff 21.43%,
    #3bace2 50.63%,
    #b936ee 100%,
    #ff56f6 117.04%
  );
}

.active {
  color: #fff;
  padding: 1rem;
  border-radius: 100%;
}

@layer base {
  body {
    @apply font-secondary text-lg leading-8 text-white;
  }
  .h2 {
    @apply font-primary text-[32px] mb-6 tracking-[10%] uppercase;
  }
  .h3 {
    @apply font-primary font-semibold text-[26px] mb-6 leading-[46px];
  }
  .btn {
    @apply rounded-full font-primary text-white font-medium;
  }
  .btn-sm {
    @apply h-[48px] px-6 text-sm;
  }
  .btn-lg {
    @apply h-[56px] px-10 text-base;
  }
  .btn-link {
    @apply text-base;
  }
  .text-gradient {
    @apply bg-gradient-to-r text-transparent bg-clip-text from-[#42A6E3] to-[#FF56F6] font-bold font-primary hover:from-[#FF56F6] hover:to-[#42A6E3];
  }
  /* .section {
    @apply py-8 lg:py-24 lg:h-screen flex items-center;
  } */
}

/* section{
  margin-bottom: 100px;
} */

.section-general{
  margin-top: 10%;
}

.contact{
  margin-bottom: 12px;
}

/* .chico{
  margin-top: 180px;
} */

/* @media (max-width: 376px) and (max-height: 668px){
  .chico {
    margin-top: 90%;
  }
} */

/* @media (max-width: 394px) and (max-height: 852px){
  .chico {
    margin-top: 180px;
  }

  .centrar-img{
    margin: 0 auto;
  }
} */

/* @media (max-width: 394px) and (max-height: 852px) {
  .chico {
    margin-top: 30px;
  }
} */

/* Estilo para el enlace */
.hover-kodemia {
  position: relative;
  display: inline-block;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

/* Efecto hover */
.hover-kodemia:hover {
  transform: scale(1.1); /* Agrandar imagen */
  /* box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.8); 
  border-radius: 10px; */
}

/* Contenedor de la superposición */
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 20%;
  height: 20%;
  background: url('./assets/click.png') no-repeat center center;
  background-size: cover;
  opacity: 0;
  transition: opacity 0.3s ease;
}

/* Mostrar la superposición en el hover */
.hover-kodemia:hover .overlay {
  opacity: 1;
}

.object-scale-down {
  object-fit: scale-down;
}

.hover\:text-shadow:hover {
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8);
}

